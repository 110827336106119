import React, { useContext } from 'react'
import { Switch, Route, useRouteMatch, Redirect } from 'react-router-dom'
import { FbContext } from '../../store/contexts/fbContext';
import HTMLEditor from './HTMLEditor';
import ArticleManager from './ArticleManager';
import ExistingArticleEditor from './ExistingArticleEditor';
import NewArticleEditor from './NewArticleEditor';
import Test from './Test';
import AdminHome from './AdminHome';
import ImageUploader from './ImageUploader';

const Admin = () => {
  const { path } = useRouteMatch();

  const { user } = useContext(FbContext)

  if(user && (user.isAdmin !== true || user.loggedIn === false) && user.userLoading !== true){
    return <Redirect to="/"/>
  }
  if(user.userLoading){
    return null
  }

  return ( 
    <Switch>
      <Route exact path={path + '/editor'} component={NewArticleEditor} />
      <Route exact path={`${path}/editor/:articleID`} component={ExistingArticleEditor} />
      <Route exact path={`${path}/manage/:articleID`} component={ArticleManager} />
      <Route exact path={`${path}/edithtml`} component={HTMLEditor} />
      <Route exact path={`${path}/imageuploader`} component={ImageUploader} />
      <Route exact path={`${path}/test`} component={Test} />
      <Route exact path={path} component={AdminHome} />
      <Route path={path}> <Redirect to={path} /> </Route>
    </Switch>
   );
}
 
export default Admin;