import { v4 as uuidv4 } from 'uuid';

export const fbReducer = (state, action) => {
    switch(action.type){
        case 'SIGN_IN_SUCCESSS':
            return {
                ...state,
                signInStatus: null
            }
        case 'SIGN_IN_ERROR':
            return {
                ...state,
                signInStatus: action.payload
            }
        case 'SIGNUP_SUCCESS':
            return{
                ...state,
                signUpStatus: null
            }
        case 'SIGNUP_ERROR':
            return{
                ...state,
                signUpStatus: action.err
            }
        case 'NEW_ARTICLE_CREATED':
            return{
                ...state,
                newArticleID: action.payload
            }
        case 'SUBMIT_FEEDBACK_SUCCESS':
          return{
            ...state,
            feedbackSubmitSuccess: true
          }
        case 'SUBMIT_FEEDBACK_ERROR':
          return{
            ...state,
            feedbackSubmitSuccess: false
          }
        case 'CLEAR_FEEDBACK':
          return{
            ...state,
            feedbackSubmitSuccess: null
          }
        case 'HOME_SIGN_UP_ERROR':
          return{
            ...state,
            signUpStatus: action.payload
          }
        case 'CLEAR_HOME_SIGN_UP_ERROR':
          return{
            ...state,
            signUpStatus: null
          }
        case 'SIGNED_UP_FOR_EMAILS':
          return{
            ...state,
            signedUpForEmails: !state.signedUpForEmails
          }
        case 'LOAD_ARTICLES':
          return{
            ...state,
            updateArticles: state.updateArticles + 1
          }
        case 'ADD_TOAST':
          const newToasts = state.toasts ? state.toasts : []
          newToasts.push({id: uuidv4(), text: action.toastText})
          return{
            ...state,
            toasts: newToasts
          }
        case 'RESET_TOAST':
          const updatedToasts = state.toasts.filter(toast => {return toast.id !== action.toastID})
          return{
            ...state,
            toasts: updatedToasts
          }
        default: 
            return state
    }
}