import React from 'react';
import { Link } from 'react-router-dom'


const AdminHome = () => {
  return ( 
    <div className="mt-5">
      <Link to="/admin/editor" className={"bg-secondary-one text-primary-three rounded-md px-5 py-3 mt-5 "}>New Article</Link>
      <Link to="/admin/edithtml" className={"bg-secondary-one text-primary-three rounded-md px-5 py-3 mt-5 ml-5 "}>Edit HTML</Link>
      <Link to="/admin/test" className={"bg-secondary-one text-primary-three rounded-md px-5 py-3 mt-5 ml-5 "}>Test</Link>
    </div>
   );
}
 
export default AdminHome;