import React, {  useEffect, useState, useReducer } from "react";
import firebase from '../../config/fbConfig'
import { useAuthState } from 'react-firebase-hooks/auth';
import {fbReducer} from '../reducers/fbReducer'
import { getCollection } from '../../config/devProd'


export const FbContext = React.createContext();


export const FbProvider = ({ children }) => {

  const [articles, setArticles] = useState(null);
  const [articlesByKey, setArticlesByKey] = useState(null)
  const [user, setUser] = useState({userLoading: true});
  const [fbStatus, dispatch] = useReducer(fbReducer, { signInStatus: null, signUpStatus: null, signedUpForEmails: false, updateArticles: 0, toasts: null });
  const [FBuser, userLoading] = useAuthState(firebase.auth());


  useEffect(() => {
    const articlesRef = user.isAdmin ? 
    firebase.firestore().collection(getCollection('articles')) :
    firebase.firestore().collection(getCollection('articles')).where('showOnSite','==',true)
    setArticles(null);
    setArticlesByKey(null);

    articlesRef.get().then(snap => {
        const articleArray = [];
        const articlesByKeyObj = {};
        snap.docs.forEach(doc => {
          const newRecord = {...doc.data(), id: doc.id}
          articleArray.push(newRecord)
          articlesByKeyObj[doc.id] = newRecord
        })
        
        articleArray.sort((a,b) => {
          if(a.isComingSoon === b.isComingSoon && a.datePublished && b.datePublished){
            return - a.datePublished.seconds + b.datePublished.seconds
          }else if(a.isComingSoon !== false){
            return 1
          }else if(a.isComingSoon === false){
            return -1
          }
          return 0
        })
        setArticles(articleArray);
        setArticlesByKey(articlesByKeyObj);
    })
  }, [user.isAdmin, fbStatus.updateArticles]);

  useEffect(() => {
    if(FBuser === null){
      setUser({loggedIn: false, userLoading})
      firebase.analytics().setUserProperties({admin: false, loggedIn: false});
    }else{
      FBuser.getIdTokenResult(true).then(idTokenResult => {
        firebase.analytics().setUserProperties({loggedIn: true});
        var isAdmin = idTokenResult.claims.admin
        if(isAdmin === undefined) isAdmin = false;
        firebase.firestore().collection(getCollection('users')).doc(FBuser.uid).get().then(userSnap => {
          const userDoc = userSnap.data();
          if(userDoc.isAdmin){
            firebase.analytics().setUserProperties({admin: true});
          }else{
            firebase.analytics().setUserProperties({admin: false});
          }
          setUser({...userDoc, id: FBuser.uid, loggedIn: true, userLoading: userLoading, token: idTokenResult.token, isAdmin, emailVerified: FBuser.emailVerified})
          if(userDoc.signedUpForEmails !== fbStatus.signedUpForEmails){
            dispatch({type: 'SIGNED_UP_FOR_EMAILS'})
          }
        })
      })
    }
  }, [FBuser, userLoading, fbStatus.signedUpForEmails]);

  return (
    <FbContext.Provider value={{ articles, user, articlesByKey, fbStatus, dispatch }}>{children}</FbContext.Provider>
  );
};
