import React, { useState, useContext } from 'react'
import { FbContext } from '../../../store/contexts/fbContext';
import { toggleUserSignedUp } from '../../../store/reducers/fbActions'

const EmailCta = () => {

  const { dispatch, fbStatus } = useContext(FbContext)
  const signedUp = fbStatus.signedUpForEmails
  const [interaction, setInteraction] = useState({
    interacted: false,
    setTo: false
  })



  const handleChange = (e) => {
    const setTo = e.target.checked === true ? true : false;
    setInteraction({
      ...interaction,
      setTo,
      interacted: true
    })
    toggleUserSignedUp(dispatch, setTo)
  }



  const style = {
    lineHeight: '1.25rem'
  }

  const statusLabel = () => {
    if(interaction.interacted){
      if(interaction.setTo === signedUp){
        if(interaction.setTo){
          return <p className="h-7 mt-5 text-primary-one font-bold">You're signed up!</p>
        }
        if(!interaction.setTo){
          return <p className="h-7 mt-5 text-red-600 font-bold">Sorry to see you go :(</p>
        }
      }else{
        return <p className="h-7 mt-5 font-bold">Loading...</p>
      }
    }else{
      return <p className="h-7 mt-5 font-bold"></p>
    }
  }

  return ( 
    <div className="stayUpdated px-5 my-10 lg:my-16 text-primary-two font-body">
      <div className="stayUpdatedText">
        <h1 className="font-heading text-4xl">Want to stay updated?</h1>
        <p>We'll email you whenever a new article is posted! Check the box below to signup.</p>
      </div>
      <div className="mt-10 flex flex-row space-x-3 content-center">
        <input onChange={handleChange} className="h-5 w-5 bg-gray-90 checked:bg-blue-600 checked:border-transparent" type="checkbox" id="emailSignup" name="emailSignup" checked={signedUp} />
        <label style={ style } className="flex-shrink " htlmfor="emailSignup">Send me email updates</label>
      </div>
      {statusLabel()}
    </div>
  );
}
 
export default EmailCta;