import React, { useContext } from 'react'
import { Editor } from '@tinymce/tinymce-react'; 
import { updateArticle } from '../../store/reducers/fbActions'
import { FbContext } from '../../store/contexts/fbContext';
import { useDocument } from 'react-firebase-hooks/firestore';
import firebase from '../../config/fbConfig'



const ExistingArticleEditor = (props) => {


  const { dispatch, articlesByKey } = useContext(FbContext)
  const urlID = props.match ? props.match.params.articleID : null
  const article = urlID && articlesByKey ? articlesByKey[urlID] : null

  const contentDocID = urlID ? urlID : '123'
  const [contentDoc, contentLoading] = useDocument(firebase.firestore().collection('articleContent').doc(contentDocID))

  const articleContent = contentDoc && contentDoc.exists ? contentDoc.data().content : null



  const saveAction = (content) => {
    updateArticle(dispatch, article.id, content)
  }


  var save = saveAction
  var init={
    height: 750,
    body_class: 'Article editor',
    browser_spellcheck: true,
    contextmenu: false,
    menubar: true,
    fixed_toolbar_container: '#mytoolbar',
    plugins: [
        'link image', 
        'charmap print preview anchor',
        'searchreplace visualblocks code',
        'table wordcount importcss fullscreen'
    ],
    toolbar:
        'undo redo | formatselect | bold italic | alignleft aligncenter alignright | bullist numlist outdent indent | help | myCustomToolbarButton',
    content_css: '../../articleEditor.css',
    importcss_append: true,
    setup: function (editor) {
        editor.ui.registry.addButton('myCustomToolbarButton', {
          text: 'Save It',
          onAction: function(j,m) {
            save(editor.getContent())
          }
        });
    }
  }

  // var parsedContent = null
  var formatedContent = null;



  if(article && articleContent && !contentLoading){
    formatedContent = articleContent.replace(/\n/g, "");

    return (
        <div className="TinyMCE">
            <div id="mytoolbar"></div>
            <Editor
                apiKey="b4urywskw9x42or9in10dx9ueuun26ilbo5qnlvanmfcesto"
                initialValue= {formatedContent}
                init={init}
                // onEditorChange={handleChange}
            />
        </div> 
    )

  }else{
    return (
        <div className="TinyMCE">

        </div> 
    )
  }

}

  
  export default ExistingArticleEditor