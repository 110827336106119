import React, { useState, useContext } from 'react';
import { updateArticle, getArticleContent } from '../../store/reducers/fbActions'
import { Link } from 'react-router-dom'
import { FbContext } from '../../store/contexts/fbContext'
var beautify = require('js-beautify').html


const HTMLEditor = (props) => {

  const [content, setContent] = useState('')
  const [articleID, setArticleID] = useState(null)
  const { dispatch, articles } = useContext(FbContext)

  var options = null
  if(articles){
    options = articles.map(article => {
      var articleTitle = article.articleTitle
      var articleID = article.id
      return (
        <option value={articleID} key={articleID}>{articleTitle}</option>
      )
    })
  }

  const handleTextChange = (e) => {
    setContent(e.target.value)
  }

  const selectChange = (e) => {
    setArticleID(e.target.value)
    getArticleContent(dispatch, e.target.value).then(newContent => {
      setContent(beautify(newContent, { indent_size: 2, indent_char: ' ', space_in_empty_paren: true }));
    })
  }

  const save = (e) => {
    updateArticle(dispatch, articleID, content)
  }

  return(
    <div>
      <select onChange={selectChange} name="article">
        <option valule="default" key="default">Select Article</option>
        {options}
      </select>
      { content !== '' ? 
        <>
        <button onClick={save} className="border border-black bg-red-100 ml-10">Save Article</button>
        <Link target="_blank" to={'/articles/' + articleID} className="border border-black bg-red-100 ml-10">See Article</Link>
        </>
        :
        null
      }
      <textarea onChange={handleTextChange} value={content} className={"border border-black w-full h-96 "}>
      </textarea>
    </div>
  )

}



export default HTMLEditor