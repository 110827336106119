import React from 'react'



const ComingSoonArticleListItem = ({ article }) => {

    return (
      <div key={article.id} className="comingSoon articleListItem">
        <div className="articleListImage w-14 h-14 md:w-20 md:h-20 flex-none">
          <img src={article.articleImage} alt="Article" className="h-14 w-14 md:h-20 md:w-20 rounded-full"/>
        </div>
        <div className="articleListItemContent flex-shrink h-full min-w-0">
          <h1 className="font-heading text-xl">{article.articleTitle}</h1>
          <p className="publishDate">Coming Soon</p>
          <p className="truncate">{article.summary}</p>
        </div>
      </div>
    )
}


export default ComingSoonArticleListItem
