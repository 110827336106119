import React, { useContext } from 'react'
import { signOut } from '../../../store/reducers/fbActions'
import { FbContext } from '../../../store/contexts/fbContext'


const Logout = (props) => {

  const {user,dispatch} = useContext(FbContext)

  if(user && user.loggedIn === true){
    signOut(dispatch)
    props.history.goBack();
  }
  if(user && user.loggedIn === false){
    props.history.goBack();
  }


  return (
      <div className="Logout">
          <p>Logging out...</p>
      </div>
    )
}


export default Logout
