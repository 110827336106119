import React, { useState } from 'react';
import firebase from '../../config/fbConfig'
import { v4 as uuidv4 } from 'uuid';

const ImageUploader = () => {

  const [image, setImage] = useState('')

  const pasted = (e) => {
    //console.log(e.clipboardData.items[0])
    var item = e.clipboardData.items[0];
 
    if (item.type.indexOf("image") === 0) {
      var blob = item.getAsFile();
      var reader = new FileReader();

      reader.onload = function(event) {

        setImage(event.target.result);

        var storageRef = firebase.storage().ref();
        var imageRef = storageRef.child('test/'+ uuidv4() +'.png');

        imageRef.put(blob).then((snapshot) => {
          snapshot.ref.getDownloadURL().then((downloadURL) => {
            console.log('File available at', downloadURL);
            e.target.value = downloadURL
          });
        });
      };
   
      reader.readAsDataURL(blob);
    }
  }


  return (
    <div>
      <p>Image Uploader</p>
      <textarea onPaste={pasted} className="block w-1/4 h-48 bg-gray-400"></textarea>
      <img src={image} alt=""></img>
    </div>
   );
}
 
export default ImageUploader;