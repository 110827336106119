import React, {useContext} from 'react'
import ArticleList from './ArticleList'
import { FbContext } from '../../../../store/contexts/fbContext'
import { Switch, Route, useRouteMatch } from 'react-router-dom'
import Article from '../Article'
import LoadingIcon from '../../../general/LoadingIcon'



const AllArticles = () => {

    const { path } = useRouteMatch();
    document.title = 'Articles';

    const { user, articles } = useContext(FbContext)
    const { isAdmin } = user

    if(!articles) return <LoadingIcon />


    return (
      <Switch>
        <Route exact path={`${path}/:articleID`} component={Article} />
        <Route path={path}>
          <ArticleList articles = {articles} isAdmin={isAdmin}/>
        </Route>
      </Switch>
    )

}


export default AllArticles
  
