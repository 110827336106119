import React, { useState, useContext, useEffect } from 'react'
import { signIn } from '../../../store/reducers/fbActions'
import { FbContext } from '../../../store/contexts/fbContext'


const Login = (props) => {

    const [creds,setCreds] = useState({email: '', password: ''})
    const {user,fbStatus,dispatch} = useContext(FbContext)


    const handleSubmit = (e) => {
        e.preventDefault();
        signIn(dispatch, creds)
    }

    const handleChange = (e) => {
        setCreds({
            ...creds,
            [e.target.id]: e.target.value
        })
    }

  useEffect(() => {
    if(user && user.loggedIn === true){
      props.history.goBack();
    }
  }, [user, props.history])


  const authError = fbStatus.signInStatus

  document.title = 'Login';
  return (
      <div className="Login min-h-halfScreen">
          <div className ="w-full sm:w-11/12 md:w-3/4 lg:w-1/2 xl:w-1/3 m-auto mt-10">
            <div className="bg-accent-one">
              <h1 className="font-heading text-xl text-primary-three p-3">Login</h1>
              <form className="mx-5 py-5" onSubmit={handleSubmit}>
                <input onChange={handleChange} placeholder="Email" autoFocus className="formInput formAnimate block mb-3 w-full" type="email" id="email" name="email" />
                <input onChange={handleChange} placeholder="Password" className="formInput formAnimate block mb-3 w-full" type="password" id="password" name="password" />
                <input className="bg-secondary-one text-primary-three rounded-md px-5 h-8" type="submit" value="Submit" />
                {authError ? <p className="text-red-600 mt-5 font-bold">{authError}</p> : null}
              </form>
            </div>
          </div>
      </div>
    )

}


export default Login
//comment