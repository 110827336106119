import React, { useContext, useState } from 'react'
import { FbContext } from '../../../store/contexts/fbContext'
import { toggleUserSignedUp } from '../../../store/reducers/fbActions'
import ChangePassword from './ChangePassword'
import UpdateEmail from './UpdateEmail'
import { Redirect } from 'react-router-dom'

const Account = (props) => {

  const { user, fbStatus, dispatch } = useContext(FbContext)
  const [interaction, setInteraction] = useState({
    interacted: false,
    setTo: false
  })

  if(!user.userLoading && user.loggedIn === false) return <Redirect to="/" />

  const signedUp = fbStatus.signedUpForEmails
  const firstName = user && user.loggedIn ? user.displayName.split(' ')[0] : null


  const handleChange = (e) => {
    const setTo = e.target.checked === true ? true : false;
    setInteraction({
      ...interaction,
      setTo,
      interacted: true
    })
    toggleUserSignedUp(dispatch, setTo)
  }

  const goToAdmin = (e) => {
    props.history.push('/admin')
  }
  
  const statusLabel = () => {
    if(interaction.interacted){
      if(interaction.setTo === signedUp){
        if(interaction.setTo){
          return <p className="flex-shrink leading-5 h-7 text-primary-one font-bold">You're signed up for emails!</p>
        }
        if(!interaction.setTo){
          return <p className="flex-shrink leading-5 h-7 text-red-600 font-bold">You have been unsubscribed from emails</p>
        }
      }else{
        return <p className="flex-shrink leading-5 h-7 font-bold">Loading...</p>
      }
    }else{
      return <p className="flex-shrink leading-5 h-7">Send Me Email Updates</p>
    }
  }

  if(user.loggedIn !== true) return null

  return ( 
    <div className="mt-10 px-20">
      <h1 className="text-3xl font-bold">Hello {firstName}!</h1>
      <p>{user.email}</p>
      { user && user.loggedIn && user.isAdmin ? 
        <button className="bg-secondary-one text-primary-three rounded-md px-5 h-8 mt-5" onClick={goToAdmin}>Admin</button>
        :
        null
      }
      <ChangePassword />
      <UpdateEmail />
      <div className="mt-10 flex flex-row space-x-3 content-center">
        <input onChange={handleChange} className="h-5 w-5 bg-gray-90 checked:bg-blue-600 checked:border-transparent" type="checkbox" id="emailSignup" name="emailSignup" checked={signedUp} />
        {statusLabel()}
      </div>
    </div>
   );
}
 
export default Account;