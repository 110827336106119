import React, { useState, useContext } from 'react'
import moment from 'moment';
import { addComment, deleteComment } from '../../../store/reducers/fbActions'
import { v4 as uuidv4 } from 'uuid';
import { FbContext } from '../../../store/contexts/fbContext'
var Filter = require('bad-words')

const Comments = (props) => {

    const profanityFilter = new Filter();

    const { dispatch } = useContext(FbContext)

    const [state, setState] = useState({
        commentInProgress: ''
    });

    const validateComment = () => {
      if(state.commentInProgress === ''){
        return false
      }
      return true
    }

    const deleteClick = (e) => {
      deleteComment(dispatch, e.target.attributes.commentid.value,props.articleID)
    }


    const onClick = (e) => {
        e.preventDefault();
        if(!validateComment()) return
        if(!props.currentUser.displayName){
            console.log('not signed in')
            return
        }
  
        const fullName = props.currentUser.displayName
        const initials = props.currentUser.initials
        const text = profanityFilter.clean(state.commentInProgress)
        const createdAt = new Date()
        const userID = props.currentUser.id
        const commentID = uuidv4();

        const newComment = {
            fullName,
            initials,
            text,
            createdAt,
            userID,
            commentID
        }
        addComment(props.dispatch,JSON.stringify(newComment),props.articleID)

        setState({
            ...state,
            commentInProgress: ''
        })
    }

    const onChange = (e) => {
        setState({
            ...state,
            commentInProgress: e.target.value
        })
    }

    const buttonViz = validateComment() ? "bg-secondary-one" : "bg-gray-90 cursor-default"

    const textareaStyle = {
        background: 'rgb(244, 247, 249)',
        border: 'none',
        width: '100%',
        resize: 'none',
        padding: '15px',
        borderRadius: '10px',
        outline: 'none',
        marginBottom: '10px',
        boxSizing: 'border-box'
    }
    const buttonStyle = {
        display: 'inline-block',
        padding: '0.85em 1em',
        fontFamily: 'inherit',
        border: 'none',
        borderRadius: '0.5em',
        lineHeight: '1.2',
        textAlign: 'center',
        textDecoration: 'none',
        outline: 'none',
        transition: 'background 0.2s ease 0s, color 0.2s ease 0s'
    }
    const divStyle = {
        display: 'flex',
        marginBottom: "25px",
        marginTop: '30px'
    }
    const IconStyle = {
        width: '45px',
        height: '45px',
        borderRadius: '50%',
        marginRight: '10px',
        backgroundSize: 'contain',
        textAlign: 'center',
        padding: '10px 0'
    }
    const contentContainerStyle = {
        display: 'flex',
        flexWrap: 'wrap',
        'width': 'calc(100% - 122px)',
        alignItems: 'center'
    }
    const authorNameContainerStyle = {
        fontSize: '18px',
        marginRight: '10px'
    }
    const dateStyle = {
        fontSize: '14px',
        color: 'rgb(164, 184, 201)'
    }
    const commentStyle = {
        fontSize: '16px',
        flex: '1 1 100%'
    }
    const active = false
    if(!active) return null
    return (
        <div>
            {props.comments ? props.comments.map(comment => {
                comment = JSON.parse(comment)
                return(
                    <div key={comment.text} style={divStyle}>
                        <div style={IconStyle} className="bg-primary-one text-primary-three">{comment.initials}</div>
                        <div style={contentContainerStyle}>
                            <div style={authorNameContainerStyle} className="text-secondary-one">
                                {comment.fullName}
                            </div>
                            <div style={dateStyle}>{moment(comment.createdAt).format("M/DD/YYYY")}</div>
                            <div style={commentStyle}>{comment.text}</div>
                        </div>
                        { comment.userID === props.currentUser.id ? 
                          <button onClick={deleteClick} commentid={comment.commentID} className="bg-secondary-one self-center text-primary-three rounded-md px-3 h-8 w-20">Delete</button>
                          :
                          null
                        }
                    </div>
                )
            })
            :
            (<h2>No comments yet!</h2>)
            }
            {props.currentUser.displayName ? 
            (<form onSubmit={onClick}>
                <textarea style={textareaStyle} placeholder="Leave a comment" value={state.commentInProgress} onChange={onChange}></textarea>
                <button style={buttonStyle} onClick={onClick} className={"text-primary-three " + buttonViz}>Comment</button>
            </form>)
            :
            (<p>Sign In to leave a comment</p>)
            }
        </div>
    )
}
//


export default Comments