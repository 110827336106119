module.exports = {
  purge: [
    './src/**/*.html',
    './src/**/*.js',
    './src/styles/articleContent.txt'
  ],
  darkMode: false, // or 'media' or 'class'
  theme: {
    extend: {
      transitionProperty: {
        'width': 'width'
       },
      lineHeight: {
        'bar': '4rem',
      },
      minHeight: {
        'card': '16rem',
        halfScreen: 'calc(50vh - 1.5rem)'
      },
      height: {
        fullScreen: 'calc(100vh - 3rem)',
        halfScreen: 'calc(50vh - 1.5rem)',
        halfScreenGap: 'calc(50vh - 1.75rem)'
      },
      colors:{
        'primary-one': '#4392F1',
        'primary-two': '#191919',
        'primary-three': '#FFFFFF',
        'primary-bg': '#FDFFFF',
        'primary-one-90': '#0B468E',
        'primary-one-80': '#0E5EBE',
        'primary-one-40': '#A0C8F8',
        'primary-one-30': '#CFE3FB',
        'secondary-one': '#00916E',
        'secondary-one-10': '#005C46',
        'accent-one': '#9046CF',
        'gray-70': '#F7F7F7',
        'gray-80': '#E5E5E5',
        'gray-90': '#CCCCCC',
        'gray-100': '#B2B2B2'
      },
      fontFamily:{
        body: ['Inter'],
        heading: ['Archivo Black']
      },
      keyframes: {
        spin : {
            from: {
              transform: 'rotate(360deg)'
            },
            to: {
              transform: 'rotate(0deg)'
            }
        }
      }
    },
  },
  variants: {
    extend: {
      backgroundColor: ['checked'],
      borderColor: ['checked']
    },
  },
  plugins: [],
}
