import React, { useEffect } from 'react'
import firebase from '../../config/fbConfig'
import { useHistory } from "react-router-dom";



const Analytics = () => {

  const logCurrentPage = () => {
    firebase.analytics().setCurrentScreen(window.location.pathname)
    if(window.location.hostname === 'localhost'){
      firebase.analytics().setUserProperties({dev: true});
    }else{
      firebase.analytics().setUserProperties({dev: false});
    }
    firebase.analytics().logEvent('screen_view')
  };

  const history = useHistory();
  useEffect(() => {
    logCurrentPage(); // log the first page visit
    history.listen(() => {
      logCurrentPage();
    });
  }, [history]);
  return (<div></div>);
};
 
export default Analytics;