import React, { useContext, useState } from 'react'
import { FbContext } from '../../../store/contexts/fbContext'
import { NavLink, Link } from 'react-router-dom'
import { useLocation } from "react-router-dom";
import Toast from './Toast'



const NewNavbar = () => {

  const { user, fbStatus, dispatch } = useContext(FbContext)
  const toasts = fbStatus.toasts

  const loggedIn = user.loggedIn ? true : false;
  const isAdmin = user && user.isAdmin && user.loggedIn === true ? true : false;

  const { pathname } = useLocation();

  const scrollUp = () => {
    window.scrollTo(0, 0);
  }

  const [prevScrollpos, setPrevScrollPos] = useState(window.pageYOffset)
  const [topClass, setTopClass] = useState('top-0')


  window.onscroll = function() {
    var currentScrollPos = window.pageYOffset;
    if (prevScrollpos > currentScrollPos) {
      setTopClass('top-0')
    } else {
      if(currentScrollPos > 40){
        setTopClass('-top-16')
      }
    }
    setPrevScrollPos(currentScrollPos);
  }


  const heightClass = "h-16"

  const style = {
    lineHeight: '4rem'
  }


  const navLinks = [
    {name: "Home", admin: false, loggedIn: true, loggedOut: true, hoverDropDown: false, mobile: false, desktop: true, to: '/', exact: true},
    {name: "Articles", admin: false, loggedIn: true, loggedOut: true, hoverDropDown: false, mobile: false, desktop: true, to: '/articles', exact: false},
    {name: "Account", admin: false, loggedIn: true, loggedOut: false, hoverDropDown: false, mobile: false, desktop: true, to: '/account', exact: false},
    {name: "Logout", admin: false, loggedIn: true, loggedOut: false, hoverDropDown: false, mobile: false, desktop: true, to: '/auth/logout', exact: true},
    {name: "Login", admin: false, loggedIn: false, loggedOut: true, hoverDropDown: false, mobile: false, desktop: true, to:'/auth/login', exact: true},
    {name: "Signup", admin: false, loggedIn: false, loggedOut: true, hoverDropDown: false, mobile: false, desktop: true, to:'/auth/signup', exact: true}
]

  return (
    <div>

      <div className={heightClass}></div>
      <nav className={"NavBar transition-top duration-500 ease-in-out bg-primary-two fixed z-10 w-full text-primary-three font-heading flex flex-row justify-between " + heightClass + " " + topClass}>
          <Link to="/" className="ml-2 sm:text-2xl md:text-4xl lg:text-4xl" style={style}>Help Me Do Money</Link>
          <div className="navLinks">
            <ul className="flex flex-row space-x-5 mr-5 text-xl" style={style}>
              {navLinks.map(navLink => {
                if(loggedIn && !navLink.loggedIn){
                        return null
                    }
                    if(!loggedIn && !navLink.loggedOut){
                        return null
                    }
                    if(!isAdmin && navLink.admin){
                        return null
                    }
                    return <li key={navLink.name} onClick={navLink.to === pathname ? scrollUp : null} className="animateHover hidden md:block"><NavLink exact={navLink.exact} to={navLink.to}>{navLink.name}</NavLink></li>
              })}
            </ul>
          </div>
          <div key="burger" style={style} className="w-12 mr-5 cursor-pointer md:hidden navbarItemDropdown relative" id="burger">
              <svg className="h-full" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 6h16M4 12h16M4 18h16" />
              </svg>
              <ul className="dropdown-mobile absolute hidden text-gray-700 pt-1 top-16 right-0">
                  {navLinks.map(navLink => {
                      if(loggedIn && !navLink.loggedIn){
                          return null
                      }
                      if(!loggedIn && !navLink.loggedOut){
                          return null
                      }
                      if(!isAdmin && navLink.admin){
                          return null
                      }
                      return (
                          <li key={navLink.name}>
                              <NavLink onClick={navLink.to === pathname ? scrollUp : null} to={navLink.to} exact={navLink.exact} className="dropdownItem py-2 px-4 font-body text-large">{navLink.name}</NavLink>
                          </li>
                      )
                  })}
              </ul>
          </div>
      </nav>
      {toasts && toasts.map(toast => {
        return <Toast key={toast.id} dispatch={dispatch} id={toast.id} text={toast.text} />
      })}
    </div>
  )
}



export default NewNavbar