import React, { useState } from 'react'
import algoliasearch from 'algoliasearch/lite';

const ArticleSearch = ({setArticleList}) => {

  const client = algoliasearch('UMX9P24FF6', 'dd87d26e2c50c215b4628a63ff2c8cf7');
  const index = client.initIndex('prod_articles');

  const [searchText, setSearchText] = useState('')
  const [activeSearch, setActiveSearch] = useState(false)

  const submitSearch = (e) => {
    e.preventDefault();
    if(searchText === ''){
      setArticleList(null)
      setActiveSearch(false)
      return;
    }
    setActiveSearch(true)
    index.search(searchText, {
      attributesToRetrieve: ['objectID']
    }).then(({ hits }) => {
      setArticleList(hits)
    });
  }

  const searchChange = (e) => {
    setSearchText(e.target.value)
    if(e.target.value === '') clearSearch()
  }

  const clearSearch = (e) => {
    setSearchText('')
    setArticleList(null)
    setActiveSearch(false)
  }

  const searchViz = activeSearch ? 'block' : 'hidden'

  return ( 
    <div>
      <form onSubmit={submitSearch}>
        <input value={searchText} className="formInput mt-5 w-3/4" onChange={searchChange} placeholder="Search"></input>
      </form>
      <p onClick={clearSearch} className={"text-gray-500 cursor-pointer " + searchViz}>Clear Search</p>
    </div>
   );
}
 
export default ArticleSearch;