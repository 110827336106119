import React, { useState, useContext } from 'react'
import { FbContext } from '../../../store/contexts/fbContext'
import { changePassword } from '../../../store/reducers/fbActions'

const ChangePassword = () => {

  const [creds, setCreds] = useState({
    currentPass: '',
    newPass: '',
    repeatNewPass: ''
  })

  const { dispatch } = useContext(FbContext)

  const inputChange = (e) => {
    setCreds({
      ...creds,
      [e.target.id]: e.target.value
    })
  }

  const handleSubmit = (e) => {
    e.preventDefault()
    changePassword(dispatch,creds)
    setCreds({
      currentPass: '',
      newPass: '',
      repeatNewPass: ''
    })
  }

  return ( 
    <div className="mt-10">
      <p className="font-bold">Change Password</p>
      <form onSubmit={handleSubmit}>
        <input type="password" className="formInput m-2" onChange={inputChange} id="currentPass" value={creds.currentPass} placeholder="Current Password"></input>
        <input type="password" className="formInput m-2" onChange={inputChange} id="newPass" value={creds.newPass} placeholder="New Password"></input>
        <input type="password" className="formInput m-2" onChange={inputChange} id="repeatNewPass" value={creds.repeatNewPass} placeholder="Repeat New Password"></input>
        <button type="submit" className="block lg:inline-block bg-secondary-one text-primary-three rounded-md px-5 h-8 m-2">Change</button>
      </form>
    </div>
   );
}
 
export default ChangePassword;