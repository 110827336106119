import React from 'react'
import { Switch, Route, useRouteMatch, Redirect } from 'react-router-dom'
import Login from './Login';
import Logout from './Logout';
import SignUp from './SignUp';


const Auth = () => {
  const { path } = useRouteMatch();
  return ( 
      <Switch>
        <Route exact path={path + '/login'} component={Login} />
        <Route exact path={path + '/logout'} component={Logout} />
        <Route exact path={`${path}/signup`} component={SignUp} />
        <Route path={path}> <Redirect to="/" /> </Route>
      </Switch>
   );
}
 
export default Auth;