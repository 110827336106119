import React, {useContext} from 'react'
import { FbContext } from '../../../store/contexts/fbContext'
import { sendVerificationEmail } from '../../../store/reducers/fbActions'


const VerifyEmail = () => {

  const { user } = useContext(FbContext)

  const loggedIn = user.loggedIn ? true : false;
  const isVerified = user ? user.emailVerified : false

  const divClass = loggedIn === true && isVerified === false ? "block" : "hidden"
  // const divClass = "hidden"

  const heightClass = "h-16"

  return (
    <div className={divClass}>
      <div className={"bg-accent-one " + heightClass}></div>
      <div className={"Verifybar fixed z-10 w-full top-16 border-b-2 border-black text-primary-two font-body bg-red-300 flex flex-row justify-between " + heightClass}>
        <h1 className="ml-0 md:ml-5 md:text-base md:leading-bar md:text-lg" >Please check your email and click the link to verify your email.</h1>
        <button onClick={sendVerificationEmail} className="resend md:mr-5 bg-secondary-one text-primary-three rounded-md px-3 lg:px-5 lg:py-3 md:my-2">Resend Email</button>
      </div>
    </div>
  )
}



export default VerifyEmail