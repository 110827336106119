import firebase from '../../config/fbConfig'
import { getCollection, apiDomain, isDev } from '../../config/devProd'
import * as EmailValidator from 'email-validator';


export const signIn = (dispatch, credentials) => {
  firebase.auth().signInWithEmailAndPassword(
      credentials.email,
      credentials.password
  ).then((d) => {
      const firstName = d.user.displayName ? d.user.displayName.split(" ")[0] : ""
      dispatch({type: 'SIGN_IN_SUCCESSS'})
      dispatch({type:'ADD_TOAST', toastText: 'Welcome ' + firstName + "!"})
  }).catch((err) => {
      var errorMessage = 'There was an error'
      if(err.code === 'auth/wrong-password') errorMessage = 'Wrong Password'
      if(err.code === 'auth/user-not-found') errorMessage = 'There is no user with this email'
      dispatch({type: 'SIGN_IN_ERROR', payload: errorMessage})
  })
}

export const signOut = async (dispatch) => {
  firebase.auth().signOut().then(() => {
      dispatch({type:'ADD_TOAST', toastText: 'Logged out'})
  })
}

export const signUp = (dispatch, creds) => {
  apiRequest('users/new','POST',creds).then(res => {
    const response = JSON.parse(res)
    if(response.success){
      dispatch({type: 'SIGNUP_SUCCESS', err: null})
      firebase.auth().signInWithEmailAndPassword(creds.email, creds.password)
    }else{
      dispatch({type: 'SIGNUP_ERROR', err: response.error})
    }

  });
}

export const changePassword = (dispatch, creds) => {
  if(creds.newPass !== creds.repeatNewPass){
    dispatch({type:'ADD_TOAST', toastText: 'Passwords do not match'})
    return
  }

  const user = firebase.auth().currentUser
  firebase.auth().signInWithEmailAndPassword(
    user.email,
    creds.currentPass
  ).then((d) => {
    firebase.auth().currentUser.updatePassword(creds.newPass).then(() => {
      dispatch({type:'ADD_TOAST', toastText: 'Password Changed!'})
    }).catch((err) => {
      dispatch({type:'ADD_TOAST', toastText: err.message})
    })
  }).catch((err) => {
    dispatch({type:'ADD_TOAST', toastText: 'Wrong Password'})
  })
}

export const updateEmail = (dispatch,creds) => {
  const user = firebase.auth().currentUser
  firebase.auth().signInWithEmailAndPassword(
    user.email,
    creds.password
  ).then((d) => {
    firebase.auth().currentUser.updateEmail(creds.newEmail).then(() => {
      dispatch({type:'ADD_TOAST', toastText: 'Email Updated!'})
    }).catch((err) => {
      dispatch({type:'ADD_TOAST', toastText: err.message})
    })
  }).catch((err) => {
    dispatch({type:'ADD_TOAST', toastText: 'Error'})
  })
}

export const updateArticle = (dispatch, articleID, content) => {
  apiRequest('articles/update','PUT',{articleID, content, dev: isDev()}).then(res => {
    const response = JSON.parse(res)
    if(response.success){
      dispatch({type:'ADD_TOAST', toastText: 'Saved'})
    }else{
      dispatch({type:'ADD_TOAST', toastText: 'Error. Please try again'})
    }
  });
};

export const updateArticleMeta = (dispatch, newMeta) => {
  apiRequest('articles/updateMeta','PUT',{...newMeta, dev: isDev()}).then(res => {
    const response = JSON.parse(res)
    if(response.success){
      dispatch({type:'ADD_TOAST', toastText: 'Saved'})
    }else{
      dispatch({type:'ADD_TOAST', toastText: 'Error. Please try again'})
    }

  });
}

export const createArticle = (dispatch, content) => {
  apiRequest('articles/new','POST',{content, dev: isDev()}).then(res => {
    const response = JSON.parse(res)
    if(response.success){
      dispatch({type: 'NEW_ARTICLE_CREATED', payload: response.newArticleID})
    }else{
      dispatch({type:'ADD_TOAST', toastText: 'Error. Please try again'})
    }
  });
}

export const addComment = (dispatch, comment,articleID) => {
  apiRequest('articles/addComment','PUT',{comment, articleID, dev: isDev()}).then(res => {
    const response = JSON.parse(res)
    if(response.success){
    }else{
      dispatch({type:'ADD_TOAST', toastText: 'Error. Please try again'})
    }
  });
}

export const deleteComment = (dispatch, commentID, articleID) => {
  apiRequest('articles/deleteComment','PUT',{commentID, articleID, dev:isDev()}).then(res => {
    const response = JSON.parse(res)
    if(response.success){
      dispatch({type:'ADD_TOAST', toastText: 'Comment deleted'})
    }else{
      dispatch({type:'ADD_TOAST', toastText: 'Error. Please try again'})
    }
  });
}

export const publishArticle = (dispatch, articleID) => {
  apiRequest('articles/publish','PUT',{articleID, dev:isDev()}).then(res => {
    const response = JSON.parse(res)
    if(response.success){
      dispatch({type:'ADD_TOAST', toastText: 'Published'})
    }else{
      dispatch({type:'ADD_TOAST', toastText: 'Error. Please try again'})
    }
  });
}

export const submitFeedback = (dispatch, feedback) => {
  apiRequest('comms/feedback','POST',{feedback, dev:isDev()}).then(res => {
    const response = JSON.parse(res)
    if(response.success){
      dispatch({type:'SUBMIT_FEEDBACK_SUCCESS'})
    }else{
      dispatch({type: 'SUBMIT_FEEDBACK_ERROR'})
    }
  });
}

export const contact = async (dispatch, message) => {
  return new Promise(resolve => {
    if(message.contactText.replace(/(\r\n|\n|\r)/gm,"").replace(/\s/g, '') === ''){
      dispatch({type:'ADD_TOAST', toastText: 'Please enter a message'})
      resolve(false)
      return;
    }
    if(!EmailValidator.validate(message.email)){
      dispatch({type:'ADD_TOAST', toastText: 'Please enter a valid email'})
      resolve(false)
      return;
    }
    if(message.contactType === ''){
      dispatch({type:'ADD_TOAST', toastText: 'Choose a message type'})
      resolve(false)
      return;
    }
    apiRequest('comms/contact','POST',{message, dev:isDev()}).then(res => {
      const response = JSON.parse(res)
      if(response.success){
        dispatch({type:'ADD_TOAST', toastText: 'Sent'})
        resolve(true)
      }else{
        dispatch({type:'ADD_TOAST', toastText: 'Error. Please try again'})
        resolve(false)
      }
    });
  });

}

export const clearFeedbackError = (dispatch) => {
  dispatch({type: 'CLEAR_FEEDBACK'})
}

export const toggleUserSignedUp = (dispatch, newValue) => {
  const uid = firebase.auth().currentUser.uid
  firebase.firestore().collection(getCollection('users')).doc(uid).update({
    signedUpForEmails: newValue
  }).then(t => {
    dispatch({type: 'SIGNED_UP_FOR_EMAILS'})
  })
}

export const sendVerificationEmail = () => {
  var user = firebase.auth().currentUser;
  user.sendEmailVerification().then(function() {
    console.log('sent')
  }).catch(function(error) {
    console.log('error')
  });
}

export const loadArticles = (dispatch) => {
  dispatch({type:'LOAD_ARTICLES'})
}

export const getArticleContent = (dispatch, articleID) => {
  return new Promise(resolve => {
    firebase.firestore().collection('articleContent').doc(articleID).get().then(contentDoc => {
      resolve(contentDoc.data().content)
    })
  });
}

export const resetToast = (dispatch, toastID) => {
  dispatch({type:'RESET_TOAST', toastID})
}

export const articleView = (articleID) => {
  firebase.analytics().logEvent('view_article', {
    articleID: articleID
  });
}

const apiRequest = async (path, type, params) => {
  return new Promise(resolve => {
    const req = new XMLHttpRequest();
  
    req.onload = function() {
      resolve(req.response)
    }
    req.onerror = function() {
      resolve(req.response)
    }
  
    const fullPath = apiDomain() + path;
    req.open(type, fullPath, true);
  
    //if user is logged in, send token
    if(firebase.auth().currentUser){
      firebase.auth().currentUser.getIdToken().then(token => {
        const bearer = 'Bearer ' + token;
        req.setRequestHeader('Authorization', bearer);
        req.send(JSON.stringify(params));
      })
    }else{
      req.send(JSON.stringify(params));
    }
  });
}