import React, { useContext } from 'react'
import { FbContext } from '../../store/contexts/fbContext'
import { getShowSize } from '../../config/devProd'

const SizeIndicator = () => {

  const { user } = useContext(FbContext)

  if(!user.isAdmin) return null
  if(!getShowSize()) return null;

  return (
    <nav className="SizeIndicator bg-white text-center fixed z-20 top-0 text-primary-two">
      <p className="sm:hidden">X-Small</p>
      <p className="hidden sm:block md:hidden lg:hidden xl:hidden 2xl:hidden">Small</p>
      <p className="hidden md:block lg:hidden xl:hidden 2xl:hidden">Medium</p>
      <p className="hidden lg:block xl:hidden 2xl:hidden">Large</p>
      <p className="hidden xl:block 2xl:hidden">XLarge</p>
      <p className="hidden 2xl:block">2XL</p>
    </nav>
  )
}



export default SizeIndicator