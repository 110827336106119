import React from 'react'
import Hero from '../../../assets/Hero2.jpeg'


const HomeCover = () => {
  return ( 
    <div className="homeCover grid grid-cols-12 grid-rows-6 h-40 sm:h-72 2xl:h-96 mt-10">
      <div className="coverText col-span-12 sm:col-span-7 row-span-6 row-start-1 py-3 px-0 ">
        <div className="coverTextMain">
          <div className="coverTextTitle font-heading text-5xl"><h1>Help Me Do Money</h1></div>
          <div className="coverTextBody text-2xl mt-5"><h1>helping you navigate your finances</h1></div>
        </div>
        <div className="coverQuote hidden md:block mt-10 2xl:mt-20">
          <p className="quote italic text-gray-600">"Compound interest is the 8th wonder of the world.  He who understands it, earns it; he who doesn’t, pays it."</p>
          <p className="quoteAuthor text-gray-600 mt-5">-Albert Einstein</p>
        </div>
      </div>
      <div className="coverImage hidden sm:block col-span-5 p-3 row-span-6 row-start-1 h-full w-full">
        <img src={Hero} alt="hero" className="object-cover w-full h-full"></img>
      </div>
    </div>
   );
}
 
export default HomeCover;