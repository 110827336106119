import firebase from './fbConfig'

var showSize = false;

var prod = true;
if(window.location.hostname !== 'localhost'){
  prod = true
  showSize= false;
}

export const getShowSize = () => showSize;

export const getCollection = (collectionName) => {
  if(collectionName === 'users') return 'users'
  if(prod !== true){
    return collectionName + 'DEV'
  }else{
    return collectionName
  }
}

export const apiDomain = () => {
  if(prod === true){
    return 'https://us-central1-help-me-do-money-ab4b7.cloudfunctions.net/'
  }else{
    return 'http://localhost:5001/help-me-do-money-ab4b7/us-central1/'
  }
}


export const updateDev = () => {
  const collections = ['articles', 'articleContent', 'feedback']

  for(var i = 0; i < collections.length; i++){
    const devCollection = collections[i] + 'DEV'
    firebase.firestore().collection(collections[i]).get().then(snap => {
      const docList = snap.docs
      docList.forEach(doc => {
        firebase.firestore().collection(devCollection).doc(doc.id).set({
          ...doc.data()
        })
      })
    })
  }
}

export const isDev = () => !prod
