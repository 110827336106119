import React from 'react'


const About = () => {

  document.title = 'About';

  return (
    <div className="container">
      <p>About</p>
    </div>
  )

}



export default About
