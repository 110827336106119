import React, { useState, useContext } from 'react'
import { FbContext } from '../../store/contexts/fbContext';
import { contact } from '../../store/reducers/fbActions'

const Contact = () => {

  document.title = 'Contact';

  const [message, setMessage] = useState({
    name: '',
    email: '',
    contactType: '',
    contactText: ''
  })

  const { dispatch } = useContext(FbContext)

  const onSubmit = (e) => {
    e.preventDefault();
    contact(dispatch, message).then(success => {
      if(success === true){
        setMessage({
          name: '',
          email: '',
          contactType: '',
          contactText: ''
        })
      }
    })
  }

  const inputChange = (e) => {
    setMessage({
      ...message,
      [e.target.id]: e.target.value
    })
  }

  return (
    <div className="contact min-h-halfScreen">
      <div className ="w-full sm:w-11/12 md:w-3/4 lg:w-1/2 xl:w-1/3 m-auto mt-10">
        <div className="bg-accent-one">
          <h1 className="font-heading text-xl text-primary-three p-3">Contact</h1>
          <form className="mx-5 py-5" onSubmit={onSubmit}>
              <input value={message.name} onChange={inputChange} className="formInput block mb-3 w-full" type='text' id="name" placeholder='Name (optional)'></input>
              <input value={message.email} onChange={inputChange} className="formInput block mb-3 w-full" type='email' id='email' placeholder="Email"></input>
              <select onChange={inputChange} value={message.contactType} className="max-w-full mb-3 formInput" name="contactType" id="contactType">
                <option value="" disabled>Message Type</option>
                <option value="budgetingQuestion">Question about budgeting</option>
                <option value="feedback">Feedback</option>
                <option value="bug">Bug</option>
                <option value="other">Other</option>
              </select>
              <textarea value={message.contactText} onChange={inputChange} className="formInput block mb-3 w-full" type='text' id='contactText' placeholder="Message"></textarea>
              <button className="bg-secondary-one text-primary-three rounded-md px-5 h-8" type="submit" onClick={onSubmit}>Submit</button>
          </form>
        </div>
      </div>
    </div>
  )

}



export default Contact
