import React, { useContext } from 'react'
import { FbContext } from '../../../store/contexts/fbContext';
import LatestArticles from './LatestArticles';
import SignupCta from './SignupCta';
import EmailCta from './EmailCta';
import HomeCover from './HomeCover';

const HomePage = () => {

  document.title = 'Help Me Do Money';

  const { articles, user } = useContext(FbContext)


  const recentArticles = articles ? articles.slice(0, 3) : null

  return (
    <div className="HomePage h-full">
      <HomeCover />
      <div className="w-full h-10"></div>
      <div className="explore">
        <LatestArticles recentArticles={recentArticles}/>
      </div>
      {user && user.loggedIn ? 
        <EmailCta />
        :
        <SignupCta />
      }
    </div>
  )
}



export default HomePage
