import React, { useState, useContext } from 'react';
import { FbContext } from '../../store/contexts/fbContext'
import { publishArticle, updateArticleMeta } from '../../store/reducers/fbActions'
import moment from 'moment';

const ArticleManager = (props) =>  {

    const [state, setState] = useState({
        articleTitle: null,
        isComingSoon: null,
        showOnSite: null,
        summary: null,
        publishDate: null,
        articleID: props.match.params.articleID
    });

    const { dispatch, articlesByKey } = useContext(FbContext)



  const changed = (e) => {
    if(e.target.type === 'checkbox'){
        setState({
            ...state,
            [e.target.id]:e.target.checked
        })
    }else{
        setState({
            ...state,
            [e.target.id]:e.target.value
        })
    }
  }

  const save = (e) => {
    updateArticleMeta(dispatch, state)
  }

  const publish = (e) => {
    publishArticle(dispatch, state.articleID)
  }

  const articleInfo = (articleID) => {
    const article = articlesByKey ? articlesByKey[articleID] : null;
    if(!article){ return null }
    const {articleTitle, isComingSoon, showOnSite, summary, datePublished } = article

    return (
          <div className="m-10">
            <input onChange={changed} id="articleTitle" defaultValue={articleTitle} className="border border-black w-auto" />
            <div></div>
            <textarea onChange={changed} id="summary" defaultValue={summary} className="border border-black w-4/12" />
            <div></div>
            <input type="checkbox" onChange={changed} id="isComingSoon" defaultChecked={isComingSoon} className="border border-black mr-4" />
            <label>Coming Soon</label>
            <div></div>
            <input type="checkbox" onChange={changed} id="showOnSite" defaultChecked={showOnSite} className="border border-black mr-4" />
            <label>Show on Site</label>
            <div className=""></div>
            <button onClick={save} className="border border-black bg-red-100">Save</button>
            <div className=""></div>
            {datePublished ? 
              <p>Publish date: {moment.unix(datePublished.seconds).format("M/DD/YYYY")}</p>
              :
              <button onClick={publish} className="border border-black bg-red-100">Publish</button>
            }
          </div>
      )
  }



  const articleID = props.match.params.articleID

  return (
    <div className="ArticleManager">
      {articleInfo(articleID)}
    </div>
  );

}

export default ArticleManager