import React, { useContext } from 'react'
import ReactParse from './ReactParse'
import { Redirect } from 'react-router'
import { Link } from 'react-router-dom'
import Comments from './Comments'
import { useDocument } from 'react-firebase-hooks/firestore';
import firebase from '../../../config/fbConfig'
import { FbContext } from '../../../store/contexts/fbContext'
import { getCollection } from '../../../config/devProd'
import LoadingIcon from '../../general/LoadingIcon'
import { articleView } from '../../../store/reducers/fbActions'


const Article = (props) => {

    const { user, dispatch } = useContext(FbContext)
    var isAdmin = user.isAdmin === true;

    var articleId = props.isAdminView ? props.adminArticleID : props.match.params.articleID;
    if(!articleId) articleId = '123'

    const articleCollection = getCollection('articles')
    const [articleDoc, articleLoading] = useDocument(firebase.firestore().collection(articleCollection).doc(articleId), {idField: 'articleId'});


    const [contentDoc, contentLoading] = useDocument(firebase.firestore().collection('articleContent').doc(articleId))
    

    const article = articleDoc ? articleDoc.data() : null;
    const content = contentDoc ? contentDoc.data() : null;

    if(article && (article.isComingSoon === true || article.showOnSite === false)){
      if(user.userLoading === false && user.isAdmin !== true){
        return(<Redirect to="/articles" />)
      }
    }

    if(articleLoading || user.userLoading || !user || contentLoading){
      return <LoadingIcon />
    }

    if(article && content && user.userLoading === false){
        articleView(articleId)
        document.title = article.articleTitle;
        return (
        <div className="">
          {
          isAdmin ? 
          <div className="flex justify-center w-full space-x-10 py-3">
            <Link to={"/admin/editor/" + articleId} target="_blank" className="border border-black bg-red-100">Edit Article</Link>
            <Link to={"/admin/manage/" + articleId} target="_blank" className="border border-black bg-red-100">Manage Article</Link>
          </div>
          : null
          }
          <div className="ArticleContainer flex justify-center mt-10">
            <div className="Article">
              <ReactParse content={content}/>
              {/* <h1 className="mt-10">Comments</h1> */}
              <Comments dispatch={dispatch} comments={article.comments} articleID={articleId} currentUser={user}></Comments>
            </div>
          </div>
        </div>

        )
    }else if(!article && !articleLoading && !props.isAdminView){
      return(<Redirect to="/articles" />)
    }else{
        return <LoadingIcon />
    }
}

  
  export default Article
