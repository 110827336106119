import React, { useState, useContext } from 'react'
import { FbContext } from '../../../store/contexts/fbContext';
import * as EmailValidator from 'email-validator';
import { signUp } from '../../../store/reducers/fbActions'


const SignupCta = () => {

  const [creds, setCreds] = useState({
    email: '',
    password: '',
    name: '',
    emailUpdates: false
  })

  const { dispatch, fbStatus } = useContext(FbContext)

  //comment

  const error = fbStatus ? fbStatus.signUpStatus : null;

  const validate = () => {
    if(creds.name === '' ) {
      dispatch({type: 'HOME_SIGN_UP_ERROR' , payload: 'Please enter a name'}); 
      return false;
    }

    if(creds.password === ''){
      dispatch({type: 'HOME_SIGN_UP_ERROR' ,payload: 'Please enter a password'}); 
      return false;
    }

    if(!EmailValidator.validate(creds.email)){
      dispatch({type: 'HOME_SIGN_UP_ERROR' ,payload: 'Invalid Email'}); 
      return false;
    }

    dispatch({type:'CLEAR_HOME_SIGN_UP_ERROR', payload: null})
    return true

  }


  const handleSubmit = (e) => {
    e.preventDefault();
    validate();
    signUp(dispatch, creds)
  }

  const handleChange = (e) => {
    dispatch({type:'CLEAR_HOME_SIGN_UP_ERROR', payload: null})
    setCreds({
      ...creds,
      [e.target.id]: e.target.value
    })
  }

  const checkChanged = (e) => {
    setCreds({
      ...creds,
      [e.target.id]: e.target.checked
    })
  }

  const buttonViz = !error && creds.email !== '' && creds.password !== '' && creds.name !== '' &&  EmailValidator.validate(creds.email) ? 'block' : 'hidden'

  const formFieldClass = 'formField inline-block m-3'

  const style = {
    lineHeight: '1.25rem'
  }

  return ( 
    <div className="stayUpdated px-5 my-10 lg:my-16 text-primary-two font-body">
      <div className="stayUpdatedText">
        <h1 className="font-heading text-4xl">Want to stay updated?</h1>
        <p>Sign up to get the updates on the latest articles on all things money</p>
      </div>
      <form className="stayUpdatedForm mt-10" onSubmit={handleSubmit}>
        <div className="formContainer ">

          <div className={formFieldClass}>
            <label htmlFor="name" className="block text-xl">Full Name</label>
            <input type="text" id="name" value={creds.name} onChange={handleChange} className={" block h-12 w-60 formInput formAnimate origin-left"}></input>
          </div>

          <div className={formFieldClass}>
            <label htmlFor="email" className="block text-xl">Email</label>
            <input type="email" id="email" value={creds.email} onChange={handleChange} className={"block h-12 w-60 formInput formAnimate origin-left"}></input>
          </div>

          <div className={formFieldClass}>
            <label htmlFor="password" className="block text-xl">Set Password</label>
            <input type="password" id="password" value={creds.password} onChange={handleChange} className={"block h-12 w-60 formInput formAnimate origin-left"}></input>
          </div>
        </div>
        <div className="m-3 flex flex-row space-x-3 content-center">
            <input onChange={checkChanged} className="h-5 w-5 bg-gray-90 checked:bg-blue-600 checked:border-transparent" type="checkbox" id="emailUpdates" name="emailUpdates" checked={creds.emailUpdates}  />
            <label style={ style } className="flex-shrink " htlmfor="emailSignup">Send me email updates</label>
        </div>
        <button type="submit" className={"bg-secondary-one text-primary-three rounded-md px-5 py-3 mt-5 " + buttonViz}>Sign up</button>
        { error ? (
          <div className="h-8 mt-5">
            <label className={"text-red-600 font-bold"}>{error}</label>
          </div>
        ) : null
        }

      </form>
    </div>
  );
}
 
export default SignupCta;