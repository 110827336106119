import React, { useContext } from 'react'
import { Link } from 'react-router-dom'
import { useLocation } from "react-router-dom";
import { FbContext } from '../../store/contexts/fbContext';
import FeedbackForm from './FeedbackForm'

const Footer = () => {


  const { pathname } = useLocation();
  const { user } = useContext(FbContext)

  const links = user && user.loggedIn ? 
  [
    {name:'Home', id:1, to:"/"},
    {name:'Account', id:2, to:"/account"},
    {name:'Articles', id:3, to:"/articles"},
    {name:'Contact', id:4, to:"/contact"}
  ]
  :
  [
    {name:'Home', id:1, to:"/"},
    {name:'Login', id:2, to:"/auth/login"},
    {name:'Articles', id:3, to:"/articles"},
    {name:'Contact', id:4, to:"/contact"}
  ]

  const scrollUp = () => {
    window.scrollTo(0, 0);
  }

  const heightClass = "h-96"


  return (
    <div>
      <div className={"mt-10 " + heightClass}></div>
      <div className={"Footer absolute bottom-0 w-full bg-black text-primary-three " + heightClass}>
        <div className="footerMainContent grid grid-cols-2 md:grid-cols-3 h-3/5 md:h-3/4">
          <div className="footerCol1 col-span-1 self-center">
            <h1 className="font-heading text-3xl md:text-6xl w-full text-center">Help Me Do Money</h1>
          </div>
          <div className="footerCol2 col-span-1 self-center">
            <div className="grid grid-cols-2 grid-rows-2 justify-items-center">
              {links.map(link => {
                if(pathname === link.to){
                  return(
                    <div key={link.id} className="animateHover">
                      <p onClick={scrollUp} className="cursor-pointer font-bold text-lg text-secondary-one">{link.name}</p>
                    </div>
                  )
                }
                return (
                  <div key={link.id} className="animateHover">
                    <Link to={link.to} className="font-bold text-lg">{link.name}</Link>
                  </div>
                )
              })}
            </div>
          </div>
          <FeedbackForm />
        </div>
        <div className="footerDisclaimer text-xs w-full text-center md:px-10">
          <p>Disclaimer</p>
          <p className="italic">I will try my best to provide accurate information throughout this website, but this document can get out of date if rules and regulations change so confirm any details with other sources. I am not a legal expert, investment advisor or broker so always take any advice/information here with a grain of salt and always do your own research!</p>
        </div>
      </div>
    </div>
  )
}



export default Footer