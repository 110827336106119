import React from 'react'
import parse, { domToReact } from 'html-react-parser';
import LineChart from '../LineChart'
// import { HashLink } from 'react-router-hash-link';



const ReactParse = ({content, contentToParse}) => {
  var parsedContent = null

  const options = {
    replace: (element) => {
      const {attribs, name} = element
      var chartData = null;
      if(name === 'div' && attribs.class === "lineChart"){
        if(element.children[0]) chartData = JSON.parse(element.children[0].data)
        return (
          <LineChart 
            chartData={chartData}
          />
        )
      }
      if(name === 'em'){
        return (
          <span className="font-bold">{domToReact(element.children, options)}</span>
        )
      }
    }
  };

    
    const articleContent = content ? content.content : null

    if(articleContent){
    var formatedContent = articleContent.replace(/\n/g, "");
    
    // formatedContent = formatedContent.replace(/[\t ]+</g, "<");
    formatedContent = formatedContent.replace(/>[\t ]+</g, "><");
    formatedContent = formatedContent.replace(/>[\t ]+$/g, ">");
    
    parsedContent = parse(formatedContent,options)
    }else{
      parsedContent = parse(contentToParse, options)
    }
  return (
    <div className="reactParse">{parsedContent}</div>
  )
}


  
export default ReactParse