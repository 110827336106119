import React, { useState } from 'react'
import ActiveArticleListItem from './ActiveArticleListItem';
import ArticleSearch from './ArticleSearch';
import ComingSoonArticleListItem from './ComingSoonArticleListItem';


const ArticleList = ({ articles, isAdmin}) => {

  const [articleList, setArticleList] = useState(null)

  if(!articles){
      return null
  }

  var articlesToShow = articles;
  if(articleList){
    articlesToShow = articlesToShow.filter(articleToShow => {
      const lookup = articleList.filter(searchResult => {
        return searchResult.objectID === articleToShow.id
      })
      if(lookup.length > 0) return true
      return false
    })
  }

  return (
  <div className="articleList min-screen w-full md:w-2/3 mt-10 mx-auto">
    <h1 className="font-heading text-5xl">All Articles</h1>
    <ArticleSearch setArticleList={setArticleList} />
    { articlesToShow && articlesToShow.map(article => {
        if(!isAdmin && article.showOnSite === false) return null
        if(article.isComingSoon && !isAdmin) return <ComingSoonArticleListItem key={article.id} article={article} />
        return <ActiveArticleListItem key={article.id} article={article} />
    })}  
  </div>
  )
}


export default ArticleList
