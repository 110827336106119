import firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/auth';
import 'firebase/analytics'
import 'firebase/storage'

// Replace this with your own config details
var config = {
    apiKey: "AIzaSyD-4A3GaQuSBxpb2F8_ZcSLgUKpsVupVpg",
    authDomain: "help-me-do-money-ab4b7.firebaseapp.com",
    databaseURL: "https://help-me-do-money-ab4b7.firebaseio.com",
    projectId: "help-me-do-money-ab4b7",
    storageBucket: "help-me-do-money-ab4b7.appspot.com",
    messagingSenderId: "523571634534",
    appId: "1:523571634534:web:57dfbcde3e44fc643768a7",
    measurementId: "G-4PTGS11KS7"
};
firebase.initializeApp(config);
firebase.firestore()//.settings({ timestampsInSnapshots: true });
firebase.analytics()
firebase.storage()

export default firebase 