import React, { useState, useContext } from 'react'
import { Editor } from '@tinymce/tinymce-react'; 
import { createArticle } from '../../store/reducers/fbActions'
import { FbContext } from '../../store/contexts/fbContext';


const NewArticleEditor = (props) => {


  const [state,setState] = useState({
    content: '',
    newArticleName: '',
    newArticleSummary: ''
  });

  const { dispatch, fbStatus } = useContext(FbContext)

  const handleChange = (content) => {
    setState({
        ...state,
        content
    })
  }

  const saveAction = (content) => {
    createArticle(dispatch, content)
  }



  if(fbStatus.newArticleID){
    window.location.href = "/admin/manage/"+fbStatus.newArticleID;
  }

  var save = saveAction
  var init={
      height: 750,
      body_class: 'Article editor',
      browser_spellcheck: true,
      contextmenu: false,
      menubar: true,
      plugins: [
          'advlist autolink lists link image', 
          'charmap print preview anchor help',
          'searchreplace visualblocks code',
          'insertdatetime media table paste wordcount importcss fullscreen'
      ],
      toolbar:
          'undo redo | formatselect | bold italic | alignleft aligncenter alignright | bullist numlist outdent indent | help | myCustomToolbarButton',
      content_css: '../../articleEditor.css',
      importcss_append: true,
      setup: function (editor) {
          editor.ui.registry.addButton('myCustomToolbarButton', {
          text: 'Save It',
          onAction: function () {
            save(editor.getContent())
          }
          });
      }
      }


      return (
          <div className="TinyMCE">
          <Editor
              apiKey="b4urywskw9x42or9in10dx9ueuun26ilbo5qnlvanmfcesto"
              initialValue= {""}
              init={init}
              onEditorChange={handleChange}
          />
      </div> 
      )
}


  export default NewArticleEditor
