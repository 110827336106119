import React, { useState, useContext } from 'react'
import * as EmailValidator from 'email-validator';
import { FbContext } from '../../store/contexts/fbContext';
import { submitFeedback, clearFeedbackError } from '../../store/reducers/fbActions'

const Footer = () => {

  const { dispatch, fbStatus } = useContext(FbContext)
  const { feedbackSubmitSuccess } = fbStatus

  const [feedback, setFeedback] = useState({
    name: '',
    email: '',
    feedback: ''
  })

  const [validation, setValidation] = useState({
    submitted: false,
    madeAnyChange: false,
    triedToSubmit: false,
    valid: false,
    error: null
  })

  const feedbackSubmit = (e) => {
    e.preventDefault();
    if(validation.valid){
      submitFeedback(dispatch, feedback)
    }else{
      console.log('not valid')
    }
  }

  const handleFormChange = (e) => {
    clearFeedbackError(dispatch)
    const newFeedback = {
      ...feedback,
      [e.target.attributes.statename.value]: e.target.value
    }
    setValidation({
      ...validation,
      madeAnyChange: true
    })

    if(EmailValidator.validate(newFeedback.email) || newFeedback.email === ""){
      if(newFeedback.feedback !== ""){
        setValidation({
          ...validation,
          valid: true,
          error: null
        })
      }else{
        setValidation({
          ...validation,
          valid: false,
          error: 'Please enter some feedback'
        })
      }
    }else{
      setValidation({
        ...validation,
        valid: false,
        error: 'Invalid Email'
      })
    }

    if(newFeedback.email === '' && newFeedback.name === '' && newFeedback.feedback === ''){
      setValidation({
        ...validation,
        valid: false,
        error: null
      })
    }

    setFeedback(newFeedback)
  }


  const buttonViz = validation.valid === true && (feedbackSubmitSuccess === null) ? 'block' : 'hidden'

  if(feedbackSubmitSuccess === true){
    return <p className="hidden md:block text-4xl w-full text-center text-secondary-one col-span-1 self-center">Success!</p>
  }

  return (
    <div className="hidden md:block footerCol3 col-span-1 self-center">
      <form onSubmit={feedbackSubmit} className="feedbackForm text-primary-two">
        <h1 className="font-heading text-xl mb-5 text-primary-three">Thoughts? Comments? Submit feedback!</h1>
        <input className="mb-3 formInput formAnimate origin-left block" id="feedbackName" statename="name" value={feedback.name} onChange={handleFormChange} placeholder="Name (optional)"></input>
        <input className="formInput formAnimate origin-left mb-3 block" id="feedbackEmail" statename="email" value={feedback.email} onChange={handleFormChange} placeholder="Email (optional)"></input>
        <textarea className="formInput formAnimate origin-left block mb-3 resize-none w-3/4" rows="3" id="feedbackText" statename="feedback" value={feedback.feedback} onChange={handleFormChange} placeholder="Comments"></textarea>
        <button type="submit" className={"bg-secondary-one text-primary-three rounded-md px-5 h-8 " + buttonViz}>Submit</button>
        {validation.valid === false && validation.error ? 
          <p className="text-red-600">{validation.error}</p> : null
        }
        {feedbackSubmitSuccess === false ? <p className="text-red-600">There was an error, please try again</p> : null}
      </form>
    </div>
  )
}



export default Footer