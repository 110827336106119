import React, { useState, useContext } from 'react'
import { FbContext } from '../../../store/contexts/fbContext'
import { updateEmail } from '../../../store/reducers/fbActions'

const UpdateEmail = () => {

  const [creds, setCreds] = useState({
    newEmail: '',
    password:''
  })

  const { dispatch } = useContext(FbContext)

  const inputChange = (e) => {
    setCreds({
      ...creds,
      [e.target.id]: e.target.value
    })
  }

  const handleSubmit = (e) => {
    e.preventDefault()
    updateEmail(dispatch, creds)
  }

  return ( 
    <div className="mt-10">
      <p className="font-bold">Update Email</p>
      <form onSubmit={handleSubmit}>
        <input type="email" className="formInput m-2" onChange={inputChange} id="newEmail" value={creds.email} placeholder="New Email"></input>
        <input type="password" className="formInput m-2" onChange={inputChange} id="password" value={creds.password} placeholder="Current Password"></input>
        <button type="submit" className="block md:inline-block bg-secondary-one text-primary-three rounded-md px-5 h-8 m-2">Update</button>
      </form>
    </div>
   );
}
 
export default UpdateEmail;