import React from 'react'
import { Link } from 'react-router-dom'
import LoadingIcon from '../LoadingIcon';

const LatestArticles = ({recentArticles}) => {

  return ( 
    <div className="recentPosts relative py-5 bg-secondary-one text-primary-three min-h-card">
      <h1 className="pl-5 font-heading text-4xl">Recent Articles</h1>
      <div className="recentPostsList my-10 flex flex-col space-y-5 md:space-y-0 md:flex-row place-content-around">
        {recentArticles ? recentArticles.map(article => {
          return (
            <Link key={article.id} to={'/articles/'+article.id} className="recentArticleCard animateHoverSmall h-64 md:h-auto lg:h-64 bg-primary-three hover:bg-gray-90 w-3/5 sm:w-2/4 md:w-1/4 text-primary-two py-5 rounded-lg mx-auto overflow-hidden">
              <img src={article.articleImage} alt="article" className="w-full h-2/4" />
              <p className="text-center font-bold">{article.articleTitle}</p>
              <p className="px-5 mt-3">{article.summary}</p>
            </Link>
          )
        })
        :
        <LoadingIcon />
        }
      </div>
      <Link className="px-5 animateHoverSmall" to="/articles">See All Articles</Link>
    </div>
    );
}
 
export default LatestArticles;