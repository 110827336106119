import React, { useState } from 'react'
// import { useContext } from 'react'
import { updateDev } from '../../config/devProd'
import algoliasearch from 'algoliasearch';
// import { FbContext } from '../../store/contexts/fbContext';
import ReactParse from '../content/articles/ReactParse';


const Test = () => {
  const client = algoliasearch('UMX9P24FF6', '4f409a5722860db03a6022a6b29eb916');
  const index = client.initIndex('prod_articles');

  // const { articles } = useContext(FbContext)
  const [results, setResults] = useState(null)
  const [searchQ, setSearchQ] = useState('')
  
  const tst = (e) => {
    setSearchQ(e.target.value)
    if(e.target.value === ''){
      setResults(null)
      return
    }
    // var objects = [];

    // if(articles){
    //   console.log(articles)
    //   articles.forEach(article => {

    //     objects.push({
    //       objectID: article.id,
    //       title: article.articleTitle,
    //       summary: article.summary,
    //       currentArticleContent: article.currentArticleContent
    //     })

    //   })

    // }

    index.search(e.target.value, {
      attributesToRetrieve: ['title', 'summary']
    }).then(({ hits }) => {
      setResults(hits)
      // console.log(hits);
    });
  
    // const objects = [{
    //   firstname: 'Rahul',
    //   lastname: 'Barninger'
    // }, {
    //   firstname: 'Rahul',
    //   lastname: 'Speach'
    // }];
    
    // index
    //   .saveObjects(objects, { autoGenerateObjectIDIfNotExist: true })
    //   .then(({ objectIDs }) => {
    //     console.log(objectIDs);
    //   });
  }
  return (
    <>
      <input value={searchQ} onChange={tst} className="formInput"></input>
      {results && results.map(result => {
        return (
          <div key={result.objectID}>
            {/* <p>{result._highlightResult.title.value}</p>
            <p>{result._highlightResult.summary.value}</p> */}
            <ReactParse contentToParse={"<p>" + result._highlightResult.title.value + "</p>"} />
            <ReactParse contentToParse={"<p>" + result._highlightResult.summary.value + "</p>"} />
          </div>
        )
      })}
      <button onClick={updateDev}>fsadsf</button>
    </>
  );
}
 
export default Test;