import React, { useState, useEffect } from 'react'
import { resetToast } from '../../../store/reducers/fbActions'

const Toast = (props) => {
 
  const [opacity, setOpacity] = useState("opacity-0")
  const [translateClass, setTranslateClass] = useState(" transform translate-x-64")
  const toastText = props.text


  useEffect(() => {
    const toastID = props.id
  
    setOpacity("opacity-100");
    setTranslateClass("");
  
    setTimeout(function(){ 
      setOpacity("opacity-0");
      setTranslateClass(" transform translate-x-64");
    }, 3000);
  
    setTimeout(function(){ 
      resetToast(props.dispatch, toastID)
    }, 3500);

  },[props.dispatch, props.id])




  return ( 
    <div className={"z-50 transition-opacity duration-500 ease-in-out fixed right-0 top-16 mt-10 mr-5 " + opacity} >
      <div className={"transition-translate duration-500 ease-in-out" + translateClass}>
        <div className="flex content-center h-16 w-64 bg-accent-one">
          <p className="ml-5 py-5 text-primary-three font-bold">{toastText}</p>
        </div>
      </div>
    </div>
   );
}
 
export default Toast;