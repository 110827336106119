import React from 'react';
import { BrowserRouter, Switch, Route, Redirect } from 'react-router-dom'
import HomePage from './components/general/home/HomePage'
import './styles/prod/tailwind.css'
import Footer from './components/general/Footer';
import { FbProvider } from './store/contexts/fbContext.js'
import SizeIndicator from './components/general/SizeIndicator';
import AllArticles from './components/content/articles/browse/AllArticles';
import ScrollToTop from './components/general/ScrollToTop';
import NewNavbar from './components/general/nav/NewNavbar';
import VerifyEmail from './components/general/nav/VerifyEmail';
import Analytics from './components/general/Analytics';
import Auth from './components/general/auth/Auth';
import Admin from './components/admin/Admin';
import About from './components/general/About'
import Contact from './components/general/Contact'
import Account from './components/general/auth/Account';
// import GetAllArticleText from './components/admin/GetAllArticleText';

const App = () => {

  return (
    <FbProvider>
    <BrowserRouter>
      <ScrollToTop />
      <div className="App font-body grid-rows-1 min-screen h-full relative">
        <SizeIndicator></SizeIndicator>
        <NewNavbar />
        <VerifyEmail />
        <main className="px-5 md:px-10">
          <Switch>
            <Route exact path='/' component={HomePage} />
            <Route path='/admin' component={Admin} />
            <Route path='/articles' component={AllArticles} />
            <Route path='/auth' component={Auth} />
            <Route path='/about' component={About} />
            <Route path='/contact' component={Contact} />
            <Route path='/account' component={Account} />
            {/* <Route exact path='/admin/get' component={GetAllArticleText} /> */}
            <Route path='/'> <Redirect to="/" /> </Route>
          </Switch>
        </main>
        <Footer></Footer>
      </div>
      <Analytics />
    </BrowserRouter>
    </FbProvider>
  );
}

export default App;
