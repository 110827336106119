import React from 'react'
import { Link } from 'react-router-dom'
import moment from 'moment';



const ActiveArticleListItem = ({ article }) => {


    if(!article) return null  
    return (
      <Link key={article.id} to={"/articles/" + article.id} className="active articleListItem">
        <div className="articleListImage w-14 h-14 md:w-20 md:h-20 flex-none">
          <img src={article.articleImage} alt="Article" className="w-14 h-14 md:w-20 md:h-20 rounded-full"/>
        </div>
        <div className="articleListItemContent flex-shrink h-full min-w-0">
          <h1 className="font-heading text-xl ">{article.articleTitle}</h1>
          {
            article.datePublished ? 
            <p className="publishDate">{moment.unix(article.datePublished.seconds).format("MM/DD/YYYY")}</p>
            :
            <p className="publishDate">Coming Soon</p>
          }
          <p className="truncate">{article.summary}</p>
        </div>
      </Link>
    )
}


export default ActiveArticleListItem
