import React, { useState, useContext } from 'react'
import { FbContext } from '../../../store/contexts/fbContext'
import { signUp } from '../../../store/reducers/fbActions'


const SignUp = (props) => {

    const [creds, setCreds] = useState({
      email: '',
      password: '',
      name: ''
    })

    const { user, fbStatus, dispatch } = useContext(FbContext)
    const signUpStatus = fbStatus.signUpStatus


    const onClick = (e) => {
      e.preventDefault();
      // props.signUpUser(creds)
      signUp(dispatch,creds)
    }

    const handleChange = (e) => {
      setCreds({
          ...creds,
          [e.target.id]: e.target.value
      })
    }


    if(user && user.loggedIn === true){
      props.history.goBack();
    }

    document.title = 'Sign Up';
    return ( 
      <div className="SignUp min-h-halfScreen">
        <div className ="w-full sm:w-11/12 md:w-3/4 lg:w-1/2 xl:w-1/3 m-auto mt-10">
          <div className="bg-accent-one">
            <h1 className="font-heading text-xl text-primary-three p-3">Sign Up</h1>
            <form className="mx-5 py-5" onSubmit={onClick}>
                <input onChange={handleChange} className="formInput formAnimate block mb-3 w-full" type='text' id="name" placeholder='Full Name'></input>
                <input onChange={handleChange} className="formInput formAnimate block mb-3 w-full" type='email' id='email' placeholder="Email"></input>
                <input onChange={handleChange} className="formInput formAnimate block mb-3 w-full" type='password' id='password' placeholder="Password"></input>
                <button className="bg-secondary-one text-primary-three rounded-md px-5 h-8" type="submit" onClick={onClick}>Sign Up</button>
                <p className="text-red-600 mt-5 font-bold">{signUpStatus}</p>
            </form>
          </div>
        </div>
      </div>
    );

}

export default SignUp